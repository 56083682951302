import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '../components/layout/Layout';
import Seo from '../components/Seo';
import RodoLayout from '../pagesContent/RodoLayout';


const Rodo = ({ location }: PageProps) => (
  <Layout location={location}>
    <Seo
      pageType="informationClause"
      title="rodo"
      keywords={[]}
      lang="en"
    />
    <RodoLayout />
  </Layout>
);

export default Rodo;
